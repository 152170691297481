<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <AppContents :content_id="content_id"/>
    </template>
    <template #fallback>
      <Spinner />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
const AppContents = defineAsyncComponent(() =>
  import("@/components/Pages/AppContents.vue")
);
export default {
  name: 'Content',
  props: {
    content_id: String
  },
  components: {
    Suspense,
    Spinner,
    AppHeader,
    AppFooter,
    AppContents
  },
}
</script>